import { Text, TextProps } from '@chakra-ui/react';

type ListEmptyTextProps = {
  text: string;
} & TextProps;

const ListEmptyText = ({ text, ...textProps }: ListEmptyTextProps) => {
  return (
    <Text
      color='gray.500'
      fontSize='16px'
      lineHeight='24px'
      py='8px'
      textAlign='center'
      {...textProps}
    >
      {text}
    </Text>
  );
};

export default ListEmptyText;
